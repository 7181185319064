import React, { useState, useEffect, useRef } from 'react';
import './Menu.css';
import logo from './images/acunmedya-logo.png';
import { FaInstagram, FaLinkedin, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';

const trainers = [
    { name: 'Murat Soysal', department: 'TELEVİZYON', image: 'https://via.placeholder.com/200x250' },
    { name: 'Mustafa Çelik', department: 'TELEVİZYON', image: 'https://via.placeholder.com/200x250' },
    { name: 'Orhan Koçak', department: 'TELEVİZYON', image: 'https://via.placeholder.com/200x250' },
    { name: 'Osman Yelkenci', department: 'TELEVİZYON', image: 'https://via.placeholder.com/200x250' },
    { name: 'Ahmet Yılmaz', department: 'RADYO', image: 'https://via.placeholder.com/200x250' },
    { name: 'Ayşe Demir', department: 'DİJİTAL', image: 'https://via.placeholder.com/200x250' },
    { name: 'Mehmet Kaya', department: 'YAPIM', image: 'https://via.placeholder.com/200x250' },
    { name: 'Zeynep Şahin', department: 'HABER', image: 'https://via.placeholder.com/200x250' },
    { name: 'Can Öztürk', department: 'TEKNİK', image: 'https://via.placeholder.com/200x250' },
    { name: 'Elif Yıldız', department: 'KURGU', image: 'https://via.placeholder.com/200x250' },
    { name: 'Burak Aydın', department: 'GÖRÜNTÜ YÖNETMENİ', image: 'https://via.placeholder.com/200x250' },
    { name: 'Seda Arslan', department: 'SENARYO', image: 'https://via.placeholder.com/200x250' },
];

const educationCategories = [
    {
        title: "Televizyon",
        image: "/path/to/television-image.jpg",
        mainCourse: "İleri Televizyonculuk Eğitimi",
        description: "Acunmedya Akademi Televizyon bölümü bünyesindeki İleri Televizyonculuk Eğitimi ile ilgi duyduğunuz alanda (prodüksiyon, post prodüksiyon, teknik prodüksiyon) uzmanlaşacak, Acun Medya profesyonellerinden işin püf noktalarını öğrenecek ve sektörde çalışmak için gerekli tüm donanımlara sahip olacaksınız.",
        courses: []
    },
    {
        title: "Gastronomi",
        image: "/path/to/gastronomy-image.jpg",
        mainCourse: "Chef Eğitimi",
        courses: ["ProChef Eğitimi", "Pastry Chef Eğitimi", "ProPastry Chef Eğitimi"]
    },
    {
        title: "Dijital",
        image: "/path/to/digital-image.jpg",
        mainCourse: "Dijital İçerik ve Yayıncılık Eğitimi",
        courses: [
            "Dijital Reklamcılık ve Web Analitiği Eğitimi",
            "E-Ticaret Yöneticiliği ve Girişimciliği Eğitimi",
            "Dijital Pazarlama Yöneticiliği Eğitimi",
            "Müzik Prodüksiyonu ve Ses Tasarımı Eğitimi",
            "Yapay Zeka ile İçerik Üretimi Eğitimi"
        ]
    },
    {
        title: "Yazılım",
        image: "/path/to/software-image.jpg",
        mainCourse: "Sıfırdan Front-End Bootcamp",
        courses: [
            "Sıfırdan Back-End Bootcamp",
            "React ile Front-End Yazılım Uzmanlığı Bootcamp",
            "Back-End Yazılım Uzmanlığı Bootcamp",
            "Powered with TechCareer"
        ]
    }
];


const Menu = () => {
    const sliderRef = useRef(null);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const scroll = (direction) => {
        if (sliderRef.current) {
            const { scrollLeft, clientWidth } = sliderRef.current;
            const scrollTo = direction === 'left'
                ? scrollLeft - clientWidth
                : scrollLeft + clientWidth;

            sliderRef.current.scrollTo({ left: scrollTo, behavior: 'smooth' });
        }
    };

    return (
        <nav className="menu">
            <div className="menu-container">
                <img src={logo} className="menu-logo" alt="Acun Medya Akademi logo" />
                <ul className="menu-list">
                    <li className="menu-item" onClick={toggleMenu}><a href="/">ANA SAYFA</a></li>
                    <li className="menu-item dropdown">
                        <a href="/egitimler">EĞİTİMLER</a>
                        <div className="dropdown-content education-dropdown">
                            <div className="education-categories">
                                {educationCategories.map((category, index) => (
                                    <div key={index} className="education-category">
                                        <h3>{category.title}</h3>
                                        <div className="category-content">
                                            <img src={category.image} alt={category.title} />
                                            <div className="courses">
                                                <h4>{category.mainCourse}</h4>
                                                {category.description && <p>{category.description}</p>}
                                                {category.courses.map((course, courseIndex) => (
                                                    <p key={courseIndex}>{course}</p>
                                                ))}
                                            </div>
                                        </div>
                                        {category.title === "Televizyon" && (
                                            <button className="kesfet-button">KEŞFET</button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </li>
                    <li className="menu-item dropdown">
                        <a href="/egitmenler">EĞİTMENLER</a>
                        <div className="dropdown-content">
                            <div className="trainers-dropdown">
                                <button className="scroll-button left" onClick={() => scroll('left')}><FaChevronLeft /></button>
                                <div className="trainers-slider" ref={sliderRef}>
                                    {trainers.map((trainer, index) => (
                                        <div key={index} className="trainer-item">
                                            <img src={trainer.image} alt={trainer.name} />
                                            <div className="trainer-info">
                                                <h3>{trainer.name}</h3>
                                                <p>{trainer.department}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <button className="scroll-button right" onClick={() => scroll('right')}><FaChevronRight /></button>
                            </div>
                        </div>
                    </li>
                    <li className="menu-item"><a href="/marka-siniflari">MARKA SINIFLARI</a></li>
                    <li className="menu-item"><a href="/mezunlar">MEZUNLAR</a></li>
                    <li className="menu-item"><a href="/etkinlikler">ETKİNLİKLER</a></li>
                    <li className="menu-item"><a href="/iletisim">İLETİŞİM</a></li>
                </ul>
                <div className="menu-right">
                    <button className="apply-button">HEMEN BAŞVUR</button>
                    <div className="social-icons">
                        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Menu;
