import React from 'react';
import logo from './logo.svg';
import './App.css';
import Menu from './Menu';
import Slider from './components/slider';
import WelcomeAcun from './components/WelcomeAcun';
import Educations from './components/Educations';
import WhyAcunmedya from './components/WhyAcunmedya';
import AcunMedyaForm from './components/AcunMedyaForm';
import { Helmet } from 'react-helmet';
import ImageSlider from './components/ImageSlider';
import Categories from './components/Categories';
import MemberTestimonials from './components/MemberTestimonials';
import FAQ from './components/FAQ';

function App() {
  return (
    <div className="App">
      <Menu />
      <Slider height="600px" />
      <WelcomeAcun />
      <Educations />
      <ImageSlider />
      <Categories />
      <WhyAcunmedya />
      <MemberTestimonials />
      <AcunMedyaForm />
      <FAQ />

      <Helmet>
        <title>Acunmedya Akademi</title>
        <meta name="description" content="Acunmedya Akademi resmi web sitesi" />
        <meta name="keywords" content="Acunmedya, Akademi, Eğitim" />
      </Helmet>
    </div>
  );
}

export default App;
