import React, { useState, useEffect } from 'react';
import './Slider.css';
import acunLine from '../images/acun_line.png';

const Slider = ({ height = '400px' }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      image: 'https://acunmedyaakademi.com/wp-content/uploads/2018/07/nua-slide1-alternatif6.jpg',
      title: 'HAYALİN İŞİN OLSUN!',
      description: 'Ünlü televizyoncular, usta şefler ve dijital dünyanın uzmanlarıyla birlikte uygulamalı eğitim ve staj fırsatlarıyla kariyerinize yön verin; hayalin gerçek olsun, hayalin işin olsun.',
      buttons: [
        { text: 'Eğitimlere Göz At', link: '#' },
        { text: 'Başvur', link: '#' }
      ]
    },
    {
      image: 'https://acunmedyaakademi.com/wp-content/uploads/2021/12/yeni-donem-temmuz-2023-v3.jpg',
      title: 'YENİ DÖNEM BAŞVURULARI BAŞLADI!',
      description: 'Ünlü televizyoncular, usta şefler ve dijital dünyanın uzmanlarıyla birlikte uygulamalı eğitim ve staj fırsatlarıyla kariyerinize yön verin; hayalin gerçek olsun, hayalin işin olsun.',
      buttons: [
        { text: 'Eğitimlere Göz At', link: '#' },
        { text: 'Başvur', link: '#' }
      ]
    },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="slider-container">
      <div className="acun-line" style={{ backgroundImage: `url(${acunLine})` }}></div>
      <div className="slider" style={{ height }}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? 'active' : ''}`}
            style={{ backgroundImage: `url(${slide.image})` }}
          >
            <div className="slide-content">
              <h1>{slide.title}</h1>
              <p>{slide.description}</p>
              <div className="slide-buttons">
                {slide.buttons.map((button, btnIndex) => (
                  <a key={btnIndex} href={button.link} className="slide-button">
                    {button.text}
                  </a>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;