import React from 'react';
import { RiStarFill } from 'react-icons/ri';
import './MovingArrows.css';

const MovingArrows = () => {
  const stars = Array.from({ length: 20 }, (_, index) => ({
    id: index,
    top: `${Math.random() * 100}%`,
    left: `${Math.random() * 100}%`,
    animationDelay: `${Math.random() * 5}s`,
    size: `${Math.random() * 1 + 0.5}em`,
    duration: `${Math.random() * 10 + 10}s`,
  }));

  return (
    <div className="moving-arrows">
      {stars.map((star) => (
        <RiStarFill
          key={star.id}
          className="star"
          style={{
            top: star.top,
            left: star.left,
            animationDelay: star.animationDelay,
            fontSize: star.size,
            animationDuration: star.duration,
          }}
        />
      ))}
    </div>
  );
};

export default MovingArrows;
