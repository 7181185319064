import React, { useState } from 'react';
import './FAQ.css';

const faqData = [
  {
    question: "AcunMedya Akademi'ye kimler başvurabilir?",
    answer: "18 yaşını doldurmuş, lise mezunu veya üniversite öğrencisi/mezunu olan herkes başvurabilir. Yaş sınırı yoktur."
  },
  {
    question: "Eğitimler ne kadar sürüyor?",
    answer: "Eğitimlerimiz genellikle 3 ay ile 6 ay arasında değişmektedir. Seçtiğiniz programa göre süre değişiklik gösterebilir."
  },
  {
    question: "Eğitimler ücretli mi?",
    answer: "Evet, eğitimlerimiz ücretlidir. Ancak başarılı öğrencilere burs imkanı sunulmaktadır. Detaylı bilgi için bizimle iletişime geçebilirsiniz."
  },
  {
    question: "Eğitimler nerede gerçekleşiyor?",
    answer: "Eğitimlerimiz İstanbul'da bulunan AcunMedya Akademi kampüsünde gerçekleşmektedir. Bazı programlarımız için online eğitim seçeneği de bulunmaktadır."
  },
  {
    question: "Mezun olduktan sonra iş imkanı sağlıyor musunuz?",
    answer: "Doğrudan iş garantisi vermemekle birlikte, başarılı öğrencilerimize AcunMedya bünyesinde staj ve iş imkanları sunulmaktadır. Ayrıca sektördeki geniş network ağımız sayesinde mezunlarımıza iş bulma konusunda destek oluyoruz."
  }
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleQuestion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h2>Sıkça Sorulan Sorular</h2>
      <div className="faq-list">
        {faqData.map((item, index) => (
          <div key={index} className="faq-item">
            <button
              className={`faq-question ${activeIndex === index ? 'active' : ''}`}
              onClick={() => toggleQuestion(index)}
            >
              {item.question}
              <span className="faq-icon">{activeIndex === index ? '-' : '+'}</span>
            </button>
            {activeIndex === index && (
              <div className="faq-answer">{item.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
export default FAQ;