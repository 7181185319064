import React, { useState } from 'react';
import './MemberTestimonials.css';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import MovingArrows from './MovingArrows';
import { RiStarFill } from 'react-icons/ri';

const testimonials = [
  {
    id: 1,
    name: "Ayşe K.",
    role: "Dijital İçerik Üreticisi",
    comment: "AcunMedya Akademi, kariyerimde büyük bir sıçrama yapmamı sağladı. Eğitimler çok kapsamlı ve uygulamalı.",
    avatar: "https://randomuser.me/api/portraits/women/1.jpg"
  },
  {
    id: 2,
    name: "Mehmet D.",
    role: "Yapım Asistanı",
    comment: "Kısa sürede çok şey öğrendim. Sektörün önde gelen isimleriyle tanışma fırsatı buldum.",
    avatar: "https://randomuser.me/api/portraits/men/1.jpg"
  },
  {
    id: 3,
    name: "Zeynep B.",
    role: "Sosyal Medya Yöneticisi",
    comment: "AcunMedya Akademi'de aldığım eğitim sayesinde hayalini kurduğum işi yapıyorum.",
    avatar: "https://randomuser.me/api/portraits/women/2.jpg"
  }
];

const MemberTestimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <div className="member-testimonials">
      <h2>Öğrencilerimiz Ne Diyor?</h2>
      <MovingArrows />

      <div className="testimonial-slider">

        <div className="testimonial-card">
          <div className="avatar-container">
            <img src={testimonials[currentIndex].avatar} alt={testimonials[currentIndex].name} className="avatar" />
          </div>
          <div className="testimonial-content">
            <p className="comment">"{testimonials[currentIndex].comment}"</p>
            <p className="name">{testimonials[currentIndex].name}</p>
            <p className="role">{testimonials[currentIndex].role}</p>
          </div>
        </div>
        <button onClick={prevTestimonial} className="nav-button prev-button">
          <ChevronLeft size={24} />
        </button>
        <button onClick={nextTestimonial} className="nav-button next-button">
          <ChevronRight size={24} />
        </button>
      </div>
    </div>
  );
};

export default MemberTestimonials;
