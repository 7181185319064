import React from 'react';
import './WelcomeAcun.css';
import acunImage from '../images/acun-ilicali-1.png';
import backgroundImage from '../images/acunbackground.png';
import acunLine from '../images/acun_line.png';

const WelcomeAcun = () => {
  return (
    <div className="welcome-acun-container">
      <div className="acun-line" style={{ backgroundImage: `url(${acunLine})` }}></div>
      <div className="welcome-acun" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="content-wrapper">
          <div className="message">
            <h2>ACUN ILICALI'DAN ÖĞRENCİLERE MESAJ</h2>
            <p>
              'Dünyaya yön verenler hayalperestlerdir'. Çok sevdiğim
              ve doğruluğuna çok inandığım bir sözdür bu.
              Arkadaşlar, hayallerinizin peşinde koşmayı asla
              bırakmayın. Çok isteyin, çok çalışın, imkanları görün,
              değerlendirin, başarı kendiliğinden gelecektir. Ben öyle
              yaptım. İstanbul Nişantaşı Üniversitesi Acunmedya
              Akademi'nin yeni eğitim döneminde hepinize başarılar
              dilerim.
            </p>
          </div>
          <div className="image-container">
            <img src={acunImage} alt="Acun Ilıcalı" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeAcun;