import React, { useEffect, useRef } from 'react';
import './LearningPlatform.css';

const LearningPlatform = () => {
  // Görsel URL'lerini oluşturuyoruz
  const images = [];
  for (let i = 1; i <= 20; i++) {
    images.push(`https://picsum.photos/400/600?random=${i}`);
  }

  // Görselleri ikili gruplara ayırıyoruz
  const imagePairs = [];
  for (let i = 0; i < images.length; i += 2) {
    imagePairs.push(images.slice(i, i + 2));
  }

  const leftImageRefs = useRef([]);

  // Görseller yüklendikten sonra hizalamayı güncellemek için
  const handleImageLoad = (index) => {
    const leftImage = leftImageRefs.current[index];
    const pair = imagePairs[index];
    if (leftImage && pair[1]) {
      const leftImageHeight = leftImage.offsetHeight;
      const rightImage = document.querySelector(`.right-image-${index}`);
      if (rightImage) {
        rightImage.style.top = `${leftImageHeight / 2}px`;
      }
    }
  };

  return (
    <div className="learning-platform">
      <div className="left-content">
        <div className="headline-text">
          HAYALİN,<br />İŞİN OLSUN.
        </div>
        <div className="paragraph-text">
          Ünlü televizyoncuların, duayen şeflerin, dijital üstadlarının yönetiminde, uygulamalı olarak ve staj imkanlarıyla hayalin gerçek olsun, hayalin işin olsun..
        </div>
        <div className="button-signup">DAHA FAZLASI</div>
      </div>
      <div className="right-content">
        <div className="image-grid">
          {imagePairs.map((pair, index) => (
            <div key={index} className="image-pair">
              <div
                className="image-wrapper left-image"
                ref={(el) => (leftImageRefs.current[index] = el)}
              >
                <img
                  src={pair[0]}
                  alt={`Image ${index * 2 + 1}`}
                  onLoad={() => handleImageLoad(index)}
                />
              </div>
              {pair[1] && (
                <div
                  className={`image-wrapper right-image right-image-${index}`}
                  style={{ position: 'absolute', left: '50%' }}
                >
                  <img src={pair[1]} alt={`Image ${index * 2 + 2}`} />
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="fade-effect"></div>
      </div>
    </div>
  );
};

export default LearningPlatform;