import React, { useState } from 'react';
import './Educations.css';
import acunLine from '../images/acun_line.png';

const Educations = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const educations = [
    {
      title: 'DİJİTAL İÇERİK VE YAYINCILIK',
      description: 'Bir markanın dijital stratejisini uçtan uca kurgulayarak, sektörde fark yaratın.',
      image: 'https://via.placeholder.com/200x300'
    },
    {
      title: 'YAZILIM',
      description: 'Dijital içerik stratejileriyle markanızı profesyonele konumlandırın ve etkili yayıncılık ile öne çıkın.',
      image: 'https://via.placeholder.com/200x300'
    },
    {
      title: 'GASTRONOMİ',
      description: 'Etkili reklam stratejileri oluşturun ve dijital yatırımlarınızı profesyonelce analiz edin.',
      image: 'https://via.placeholder.com/200x300'
    },
    // Daha fazla eğitim ekleyebilirsiniz
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % educations.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + educations.length) % educations.length);
  };

  return (
    <div className="educations-container">
      <div className="acun-line" style={{ backgroundImage: `url(${acunLine})` }}></div>
      <h1>EĞİTİMLER</h1>
      <p className="description">
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et
        quasi architecto beatae vitae dicta sunt explicabo.
      </p>
      <div className="educations-slider">
        <button className="slider-button prev" onClick={prevSlide}>&lt;</button>
        <div className="educations-grid" style={{ transform: `translateX(-${currentIndex * 500}px)` }}>
          {educations.map((education, index) => (
            <div key={index} className="education-item">
              <div className="education-image">
                <img src={education.image} alt={education.title} />
              </div>
              <div className="education-content">
                <h3>{education.title}</h3>
                <p>{education.description}</p>
                <button className="incele-button">İNCELE</button>
              </div>
            </div>
          ))}
        </div>
        <button className="slider-button next" onClick={nextSlide}>&gt;</button>
      </div>
    </div>
  );
};

export default Educations;