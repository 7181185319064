import React from 'react';
import './WhyAcunmedya.css';
import acunLine from '../images/acun_line.png';

const WhyAcunmedya = () => {
    return (
        <div className="why-acunmedya-wrapper"> {/* Yeni eklenen wrapper */}
            <div className="why-acunmedya">
                {/* <div className="acun-line" style={{ backgroundImage: `url(${acunLine})` }}></div> */}
                <h1>NEDEN ACUNMEDYA AKADEMİ?</h1>
                <p className="subtitle">
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
                    rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                </p>
                <div className="content-container">
                    <div className="column">
                        <h2>AMAÇLARIMIZ</h2>
                        <ul>
                            <li>
                                Yeni mezunlar, kariyerine yön vermek veya ilerlemek isteyenler için özel eğitim
                                programları sunarak, kişisel ve profesyonel gelişimlerine katkı sağlamak.
                            </li>
                            <li>
                                Herkesin kendi yolunu keşfetmesini ve kendi becerilerini, tutkularını ve yeteneklerini en iyi
                                şekilde kullanmasını sağlamak.
                            </li>
                            <li>
                                Sektör liderleriyle bağlantılar kurarak yaratıcı çözümler geliştirmek ve yeni fırsatlar
                                yaratmak.
                            </li>
                            <li>
                                Öğrencilerimize yenilikçi ve uygulamalı eğitim yöntemleriyle bilgi ve becerilerini doğrudan
                                gelir elde edebilecekleri veya kariyerlerinde fark yaratabilecekleri şekilde dönüştürme
                                imkanı sunmak.
                            </li>
                        </ul>
                    </div>
                    <div className="column">
                        <h2>KATKILARIMIZ</h2>
                        <ul>
                            <li>
                                <strong>Kendini Keşfetme ve Geliştirme:</strong> Hangi alanda ilerlemek istediğinize karar verin; iş
                                dünyasında güçlü bir başlangıç yapmak için gerekli beceri ve bilgileri edinin.
                            </li>
                            <li>
                                <strong>Ağ Oluşturma ve İlişki Geliştirme:</strong> Sektörün önemli isimleriyle bağlantılar kurarak kariyer
                                yolculuğunuzda güçlü bir network oluşturun.
                            </li>
                            <li>
                                <strong>Girişimci Ruhu ve İnovasyon:</strong> Kendi projelerinizi hayata geçirmenize, fikirlerinizi
                                bir iş modeline dönüştürmenize ve pazarda yer edinmenize destek olun.
                            </li>
                            <li>
                                <strong>Kariyer Yolunuzu Belirleme:</strong> Kendi işinizi kurmak veya mevcut kariyerinizde ilerlemek
                                için gereken stratejiler ve uygulamalı bilgilerle donatılın.
                            </li>
                            <li>
                                <strong>Pratik ve Uygulamalı Deneyim:</strong> Teoriden çok pratiğe odaklanan eğitim modülleriyle gerçek
                                dünya becerilerini kazanın, projelerinizi hayata geçirin ve yeni iş fırsatları yaratın.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyAcunmedya;