import React, { useState, useRef } from 'react';
import { Tv, Utensils, Monitor, PenTool, ChevronLeft, ChevronRight } from 'lucide-react';
import './MenuComponent.css';

const MenuItem = ({ icon: Icon, text, isActive, onClick }) => (
  <button
    className={`menu-item ${isActive ? 'active' : ''}`}
    onClick={onClick}
  >
    <Icon size={20} color={isActive ? '#FFC107' : '#9CA3AF'} />
    <span>{text}</span>
  </button>
);

const CourseCard = ({ title, instructor, duration, imageUrl }) => (
  <div className="course-card" style={{ backgroundImage: `url(${imageUrl})` }}>
    <div className="course-card-content">
      <h3>{title}</h3>
      <p>{instructor}</p>
      <p>{duration}</p>
    </div>
  </div>
);

const MenuComponent = () => {
  const [activeCategory, setActiveCategory] = useState('Televizyon');
  const [fadeIn, setFadeIn] = useState(true);
  const coursesRef = useRef(null);

  const handleCategoryChange = (category) => {
    setFadeIn(false);
    setTimeout(() => {
      setActiveCategory(category);
      setFadeIn(true);
      // Kaydırmayı en başa al
      if (coursesRef.current) {
        coursesRef.current.scrollLeft = 0;
      }
    }, 0);
  };

  const handlePrev = () => {
    if (coursesRef.current) {
      coursesRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const handleNext = () => {
    if (coursesRef.current) {
      coursesRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  const menuItems = [
    { icon: Tv, text: "Televizyon" },
    { icon: Utensils, text: "Gastronomi" },
    { icon: Monitor, text: "Dijital" },
    { icon: PenTool, text: "Yazılım" },
  ];

  const courses = {
    Televizyon: [
      {
        title: "İleri Televizyonculuk Eğitimi",
        instructor: "Acunmedya Akademi",
        duration: "10 saat",
        imageUrl: "https://picsum.photos/240/426?random=1",
      },
    ],
    Gastronomi: [
      {
        title: "Chef Eğitimi",
        instructor: "Acunmedya Akademi",
        duration: "8 saat",
        imageUrl: "https://picsum.photos/240/426?random=2",
      },
      {
        title: "ProChef Eğitimi",
        instructor: "Acunmedya Akademi",
        duration: "12 saat",
        imageUrl: "https://picsum.photos/240/426?random=3",
      },
      {
        title: "Pastry Chef Eğitimi",
        instructor: "Acunmedya Akademi",
        duration: "9 saat",
        imageUrl: "https://picsum.photos/240/426?random=4",
      },
      {
        title: "ProPastry Chef Eğitimi",
        instructor: "Acunmedya Akademi",
        duration: "13 saat",
        imageUrl: "https://picsum.photos/240/426?random=5",
      },
    ],
    Dijital: [
      {
        title: "Dijital İçerik ve Yayıncılık Eğitimi",
        instructor: "Acunmedya Akademi",
        duration: "15 saat",
        imageUrl: "https://picsum.photos/240/426?random=6",
      },
      {
        title: "Dijital Reklamcılık ve Web Analitiği Eğitimi",
        instructor: "Acunmedya Akademi",
        duration: "10 saat",
        imageUrl: "https://picsum.photos/240/426?random=7",
      },
      {
        title: "E-Ticaret Yöneticiliği ve Girişimciliği Eğitimi",
        instructor: "Acunmedya Akademi",
        duration: "14 saat",
        imageUrl: "https://picsum.photos/240/426?random=8",
      },
      {
        title: "Dijital Pazarlama Yöneticiliği Eğitimi",
        instructor: "Acunmedya Akademi",
        duration: "11 saat",
        imageUrl: "https://picsum.photos/240/426?random=9",
      },
      {
        title: "Müzik Prodüksiyonu ve Ses Tasarımı Eğitimi",
        instructor: "Acunmedya Akademi",
        duration: "9 saat",
        imageUrl: "https://picsum.photos/240/426?random=10",
      },
      {
        title: "Yapay Zeka ile İçerik Üretimi Eğitimi",
        instructor: "Acunmedya Akademi",
        duration: "7 saat",
        imageUrl: "https://picsum.photos/240/426?random=11",
      },
    ],
    Yazılım: [
      {
        title: "Sıfırdan Front-End Bootcamp",
        instructor: "Acunmedya Akademi",
        duration: "40 saat",
        imageUrl: "https://picsum.photos/240/426?random=12",
      },
      {
        title: "Sıfırdan Back-End Bootcamp",
        instructor: "Acunmedya Akademi",
        duration: "45 saat",
        imageUrl: "https://picsum.photos/240/426?random=13",
      },
      {
        title: "React ile Front-End Yazılım Uzmanlığı Bootcamp",
        instructor: "Acunmedya Akademi",
        duration: "50 saat",
        imageUrl: "https://picsum.photos/240/426?random=14",
      },
      {
        title: "Back-End Yazılım Uzmanlığı Bootcamp",
        instructor: "Acunmedya Akademi",
        duration: "55 saat",
        imageUrl: "https://picsum.photos/240/426?random=15",
      },
    ],
  };

  const currentCourses = courses[activeCategory] || [];

  return (
    <div className="menu-component">
      <h1 className="menu-component-heading">
        HAYALİNE ULAŞMAK İÇİN HANGİ EĞİTİMİ SEÇMELİSİN?
      </h1>

      {/* Kategori Butonları */}
      <div className="menu-items">
        {menuItems.map((item) => (
          <MenuItem
            key={item.text}
            icon={item.icon}
            text={item.text}
            isActive={activeCategory === item.text}
            onClick={() => handleCategoryChange(item.text)}
          />
        ))}
      </div>

      {/* Kurslar */}
      <div>
        <h2 className="category-title">{activeCategory} Eğitimleri</h2>
        <div className="courses-container">
          <div
            className="courses"
            ref={coursesRef}
            style={{ opacity: fadeIn ? 1 : 0 }}
          >
            {currentCourses.map((course, index) => (
              <CourseCard key={index} {...course} />
            ))}
          </div>

          {/* Oklar ve Gradient */}
          {currentCourses.length > 5 && (
            <>
              <div className="arrows">
                <button className="arrow-button" onClick={handlePrev}>
                  <ChevronLeft size={24} color="white" />
                </button>
                <button className="arrow-button" onClick={handleNext}>
                  <ChevronRight size={24} color="white" />
                </button>
              </div>
              <div className="fade-gradient"></div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MenuComponent;